<template>
  <div class="action-stepper">
    <div class="action-bar">
      <div v-for="(step, idx) of stepsTitle" v-bind:key="idx"
           :class="{ 'action-item': !step.isDivider, 'action-bar-divider' : step.isDivider }">
        <div class="action-icon" :class="{ active: step.active}" v-if="!step.isDivider">
          <i class="fa fa-circle-thin action-icon-circle" v-if="!step.active && !step.complete"></i>
          <i class="fa fa-circle-o action-icon-circle" v-if="step.active && !step.complete"></i>
          <i class="fa fa-check-circle-o action-icon-circle" v-if="step.complete"></i>
          <div class="action-icon-number" v-if="!step.complete">{{step.actionIdx + 1}}</div>
        </div>
        <div class="action-title" :class="{ active: step.active}">{{step.title}}</div>
      </div>
    </div>
    <div v-for="(step, idx) of stepsInternal" v-bind:key="idx">
      <div v-show="step.active" class="action-content">
        <slot :name="step.name"></slot>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'action-stepper',
  props: {
    steps: Array,
    value: Number
  },
  data: function () {
    return {
      showButtons: true,
      currentStep: 0,
      stepsTitle: [],
      stepsInternal: []
    }
  },
  created () {
    if (this.steps) {
      this.updateSteps(this.steps)
    }
  },
  methods: {
    // Take an array of step objects, populate the title and internal cache
    updateSteps: function (steps) {
      this.stepsTitle = []
      this.stepsInternal = [...steps]
      steps.forEach((step, idx) => {
        step.actionIdx = idx
        this.stepsTitle.push(step)
        if (idx !== (steps.length - 1)) {
          this.stepsTitle.push({ isDivider: true })
        }
      })
    },
    // Move to the next action
    nextAction: function () {
      let activeAction = this.stepsInternal.find(x => x.active)
      if (!activeAction) {
        console.log('Tried to move to next action, but could not find active action!')
      } else {
        let nextIdx = activeAction.actionIdx + 1
        if (nextIdx >= this.stepsInternal.length) {
          console.log('Action-Stepper: Tried to advance past last action step!')
        } else {
          activeAction.active = false
          let nextAction = this.stepsInternal.find(x => x.actionIdx === nextIdx)
          this.currentStep = nextIdx
          nextAction.active = true
        }
      }
      return this.currentStep
    },
    // Move to the next action
    prevAction: function () {
      let activeAction = this.stepsInternal.find(x => x.active)
      if (!activeAction) {
        console.log('Tried to move to next action, but could not find active action!')
      } else {
        let nextIdx = activeAction.actionIdx - 1
        if (nextIdx < 0) {
          console.log('Action-Stepper: Tried to advance past first action step!')
        } else {
          activeAction.active = false
          let nextAction = this.stepsInternal.find(x => x.actionIdx === nextIdx)
          this.currentStep = nextIdx
          nextAction.active = true
        }
      }
      return this.currentStep
    }
  },
  watch: {
    steps: async function (newVal) {
      this.updateSteps(newVal)
    }
  },
  computed: {
    dividerWidth: function () {
      return {
        '--divider-width': (80 / this.stepsInternal.length) + '%'
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
  @import '../variables';

  .action-stepper {
    display: flex;
    flex-direction: column;
  }

  .action-bar {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-around;
    align-items: center;
  }

  .action-icon-circle {
    text-align: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 3.5rem;
  }

  .action-icon-number {
    text-align: center;
    font-size: 2.10rem;
    position: absolute;
    //top: -3.25rem;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .action-icon {
    position: relative;
    height: 3.5rem;
  }

  .action-title {
    font-size: 1.5rem;
  }

  .active {
    color: $theme-color-primary-3;
  }

  .action-bar-divider {
    flex-grow: 1;
    background: $theme-color-secondary-1;
    height: 0.5rem;
  }

  .action-content {
    margin: 1% 1% 1% 1%;
  }

  @media screen and (max-width: 600px) {
    .action-title {
      display: none;
    }
    .action-item {
      min-width: 3.5em;
    }

  }

  @media screen and (max-width: 420px) {
    .action-item {
      min-width: 1.5em;
    }
    .action-title {
      display: none;
    }
   .action-bar {
     font-size: 1em;
   }
    .action-bar-divider {
      height: 0.2em;
    }
    .action-icon-circle {
      font-size: 1.5em;
    }
    .action-icon-number {
      font-size: 1em;
    }
  }
</style>
