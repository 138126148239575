var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "action-stepper" },
    [
      _c(
        "div",
        { staticClass: "action-bar" },
        _vm._l(_vm.stepsTitle, function(step, idx) {
          return _c(
            "div",
            {
              key: idx,
              class: {
                "action-item": !step.isDivider,
                "action-bar-divider": step.isDivider
              }
            },
            [
              !step.isDivider
                ? _c(
                    "div",
                    {
                      staticClass: "action-icon",
                      class: { active: step.active }
                    },
                    [
                      !step.active && !step.complete
                        ? _c("i", {
                            staticClass: "fa fa-circle-thin action-icon-circle"
                          })
                        : _vm._e(),
                      step.active && !step.complete
                        ? _c("i", {
                            staticClass: "fa fa-circle-o action-icon-circle"
                          })
                        : _vm._e(),
                      step.complete
                        ? _c("i", {
                            staticClass:
                              "fa fa-check-circle-o action-icon-circle"
                          })
                        : _vm._e(),
                      !step.complete
                        ? _c("div", { staticClass: "action-icon-number" }, [
                            _vm._v(_vm._s(step.actionIdx + 1))
                          ])
                        : _vm._e()
                    ]
                  )
                : _vm._e(),
              _c(
                "div",
                { staticClass: "action-title", class: { active: step.active } },
                [_vm._v(_vm._s(step.title))]
              )
            ]
          )
        }),
        0
      ),
      _vm._l(_vm.stepsInternal, function(step, idx) {
        return _c("div", { key: idx }, [
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: step.active,
                  expression: "step.active"
                }
              ],
              staticClass: "action-content"
            },
            [_vm._t(step.name)],
            2
          )
        ])
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }